import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
} from "@mui/material";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserListHead } from "../components/_dashboard/user";
//
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LoadingButton } from "@mui/lab";
import { services } from "src/services";
import { networkConfigs } from "src/helper/metamask";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "srNo", label: "Sr No", alignRight: false },
  { id: "networkName", label: "Network Name", alignRight: false },
  { id: "" },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "30px",
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [networks, setNetwork] = useState([]);
  const [openThird, setOpenThird] = useState(false);
  const [networkName, setNetworkName] = useState("");
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getNetworkList() {
      const url = `/network/list`;
      const getNetworkList = await services.Get(url);

      if (getNetworkList.status === 200) {
        setNetwork(getNetworkList.data.data);
      }
    }
    getNetworkList();
  }, []);

  const getNetworkList = async () => {
    const url = `/network/list`;
    const getNetworkList = await services.Get(url);

    if (getNetworkList.status === 200) {
      setNetwork(getNetworkList.data.data);
    }
  };

  const addNewNetwork = async (e) => {
    setLoading(true);
    e.preventDefault();
    const url = `/network/add`;
    const data = {
      networkName: networkName,
    };
    const sendData = await services.Post(url, data);

    if (sendData.status === 200) {
      setOpenThird(false);
      getNetworkList();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const emptyRows = networkConfigs.length;

  const isUserNotFound = networks.length === 0;

  return (
    <Page title="Network | Luval Snapshot">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Network
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to=""
            onClick={() => setOpenThird(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            Add Network
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={networks.length}
                  pooltab={true}
                />
                <TableBody>
                  {networks.map((networks, index) => (
                    <TableRow hover tabIndex={-1}>
                      <TableCell spacing={2}>{index + 1}</TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {networks.networkName}
                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={2} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow></TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={networks.length}
            rowsPerPage={20}
            page={1 - 1}
            // onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Modal
        open={openThird}
        onClose={() => setOpenThird(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>
          <form onSubmit={addNewNetwork}>
            <TextField
              fullWidth
              autoComplete="contract"
              type="text"
              label="Add Network Name"
              onChange={(e) => {
                setNetworkName(e.target.value);
              }}
              required
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              loading={isLoading}
              variant="contained"
              style={{ marginTop: "25px" }}
            >
              Submit
            </LoadingButton>
          </form>
        </Box>
      </Modal>
    </Page>
  );
}
