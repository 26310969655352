import * as React from "react";
import { CSVLink } from "react-csv";
import { web3 } from "../web3";
import journeyAbi from "./journy.json";
// material
import {
  Card,
  Button,
  Stack,
  TextField,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import * as XLSX from "xlsx";
import { Icon } from "@iconify/react";
import ArrowBackOutline from "@iconify/icons-eva/arrow-back-outline";
import CloudDownloadOutline from "@iconify/icons-eva/cloud-download-outline";
import data from "@iconify/icons-eva/menu-2-fill";
import { array } from "prop-types";

class HistorialSnapshot extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      daysContacts: "",
      invalidAddress: [],
      historialsnapshotname: "",
      isLoading: false,
      timestamp: 0,
      vesting_percentage: 0,
      addcsvFile: null,
      invtData: [],
      csvData: [],
      dataArr: [],
      fromIt: 1,
      toIt: 1000,
      fileError: false,
      headers: [
        { label: "walletAddress", key: "walletAddress" },
        { label: "eTokens", key: "eTokens" },
      ],
    };
  }

  // read file
  readFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      // / Parse data /;
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      // / Get first worksheet /;
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // / Convert array of arrays /;
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      //   const newData = { data };
      //   console.log("wsname", wsname);
      //   console.log("bstr", bstr);
      //   console.log("ws", ws);
      // console.log(data);
      this.processData(data);
    };
    reader.readAsBinaryString(file);
  };

  // process data
  processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    // console.log("list", list);
    this.setState({ invtData: list });
    // console.log("invtData", this.state.invtData);
  };
  stakingFunction = () => {
    function parseNumber(strg) {
      strg = strg.toString().replace(/[^\d\.\-]/g, "");
      return parseFloat(strg);
    }
    const emptyArr = [];
    const daysContacts = this.state.daysContacts;
    // const daysContacts1 = "0x8900475bf7ed42efcacf9ae8cfc24aa96098f776";
    let filterContract = this.state.invtData.filter((data) => {
      return (
        (data.To == daysContacts || data.From == daysContacts) &&
        data.TokenSymbol == "SFUND"
      );
    });
    // console.log("invdata", this.state.invtData);
    // console.log("filterContract", filterContract);

    let stakingContract = [...filterContract];
    let newArr = [];
    for (let i = 0; i < stakingContract.length; i++) {
      let curr = stakingContract[i];
      console.log("curr", curr);
      if (curr.From == daysContacts) {
        const ni = newArr.findIndex((ele) => ele.walletAddress === curr.To);
        newArr[ni].eTokens = 0;
      } else if (curr.To == daysContacts) {
        const ni = newArr.findIndex((ele) => ele.walletAddress === curr.From);
        console.log("ni", ni);
        if (ni !== -1) {
          newArr[ni].eTokens = newArr[ni].eTokens + parseNumber(curr.Value);
        } else {
          newArr.push({
            walletAddress: curr.From,
            eTokens: parseNumber(curr.Value),
          });
        }
      }
    }
    this.setState({ csvData: newArr });
    console.log("this is new Arr", newArr);

    // for (let i = 0; i < stakingContract.length; i++) {
    //   let curr = stakingContract[i];
    //   for (let j = i + 1; j < stakingContract.length; j++) {
    //     if (curr.From == stakingContract[j].From) {
    //       stakingContract[i].Value =
    //         parseNumber(stakingContract[i].Value) +
    //         parseNumber(stakingContract[j].Value);
    //       stakingContract.splice(j, 1);
    //     } else if (
    //       curr.To == stakingContract[j]?.From &&
    //       curr.From == stakingContract[j]?.To
    //     ) {
    //       stakingContract[i].Value = 0;
    //       stakingContract.splice(j, 1);

    //       // parseFloat(stakingContract[i].Value) -
    //       // parseFloat(stakingContract[j].Value);
    //       // stakingContract.splice(j, 1);
    //     }
    //     // test
    //     if (
    //       "0x63930083c0bfc3c93725d8e995400d8f46bd901a" ===
    //         stakingContract[i]?.From ||
    //       "0x63930083c0bfc3c93725d8e995400d8f46bd901a" ===
    //         stakingContract[i]?.To
    //     ) {
    //       console.log(
    //         "find",
    //         i,
    //         j,
    //         stakingContract[i].Value,
    //         stakingContract[j].Value
    //       );
    //     }
    //   }
    // }
    // console.log("filterContract", filterContract.length);
    // const mapedValue = filterContract
    //   .filter((res) => res.To == daysContacts)
    //   .map((data) => {
    //     return {
    //       walletAddress: data.From,
    //       eTokens: parseNumber(data.Value),
    //     };
    //   });
    // console.log("mapedValue", mapedValue);
  };

  onSubmit = (e) => {
    e.preventDefault();
    // console.log(Date.parse(this.state.timestamp));
    // console.log(this.state.invtData[0]);
    this.stakingFunction();

    // this.setState({ isLoading: true });
    // let csvData = [];
    // let invalidAddress = [];

    // let arr = this.state.invtData;
    // console.log(arr);
    // var holder = {};
    // arr.forEach(function (d, id) {
    //   if (web3.utils.isAddress(d.From)) {
    //     console.log(d.From);
    //     if (holder.hasOwnProperty(d.From)) {
    //       holder[d.From] = Number(holder[d.From]) + Number(d.Value);
    //     } else {
    //       holder[d.From] = Number(d.Value);
    //     }
    //   } else {
    //     console.log(d, id);
    //     invalidAddress.push({ walletAddress: d.From, eTokens: d.Value });
    //   }
    // });
    // console.log(holder);
    // var invtData = []; // apply rewards tokens
    // for (var prop in holder) {
    //   invtData.push({
    //     walletAddress: prop,
    //     eTokens: Number(holder[prop]) * Number(this.state.timestamp),
    //   });
    // }

    // invtData.map((obj) => {
    //   // apply vesting percentage
    //   if (obj.walletAddress && obj.eTokens) {
    //     csvData.push({
    //       walletAddress: obj.walletAddress,
    //       eTokens:
    //         Number(obj.eTokens) * Number(this.state.vesting_percentage / 100),
    //     });
    //   }
    // });
    // csvData.length > 0
    //   ? this.setState({ csvData: csvData, invalidAddress: invalidAddress })
    //   : this.setState({ isLoading: false, fileError: true });
  };

  //   getNFTjourneyDetails = async () => {
  //     ////////////////////////////////////////////////////////////////////////
  //     function range(start, end) {
  //       return Array(end - start + 1)
  //         .fill()
  //         .map((_, idx) => start + idx);
  //     }
  //     //////////////////////////////////////////////////////////////////////
  //     try {
  //       const contractInstance = new web3.eth.Contract(
  //         journeyAbi,
  //         "0x0b4B2bA334f476C8F41bFe52A428D6891755554d"
  //       );
  //       const arr = range(this.state.fromIt, this.state.toIt);
  //       const dataArr = await Promise.all(
  //         arr.map(async (key)

  //  => {
  //           return await contractInstance.methods.ownerOf(+key).call();
  //         })
  //       );
  //       console.log(
  //         "newData",
  //         dataArr,
  //         "oldDate",
  //         this.state.dataArr,
  //         this.state.fromIt,
  //         this.state.toIt
  //       );
  //       this.setState({
  //         dataArr: [...this.state.dataArr, ...dataArr],
  //       });
  //       this.setState({
  //         fromIt: this.state.fromIt + 1000,
  //         toIt: this.state.toIt + 1000,
  //       });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   makeCSV = () => {
  //     const csvData = [];
  //     this.state.dataArr.map((address, key) => {
  //       // apply vesting percentage
  //       csvData.push({
  //         walletAddress: address,
  //         eTokens: key + 1,
  //       });
  //     });
  //     csvData.length > 0
  //       ? this.setState({ csvData: csvData })
  //       : this.setState({ isLoading: false, fileError: true });
  //   };

  render() {
    console.log(this.state.invalidAddress);
    return (
      <Page title="Generate CSV File | Luval Snapshot">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography
              variant="h4"
              gutterBottom
              //   onClick={() => this.getNFTjourneyDetails()}
            >
              Generate CSV (Historial Snapshot)
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              //   onClick={() => this.makeCSV()}
            >
              MakeCSV
            </Typography>
            {this.state.isLoading && this.state.csvData && (
              <Button
                variant="contained"
                onClick={() => window.location.reload()}
              >
                <Icon icon={ArrowBackOutline} style={{ fontSize: "20px" }} />{" "}
                &nbsp;Back
              </Button>
            )}
          </Stack>

          <Card style={{ padding: "20px" }}>
            <form onSubmit={(e) => this.onSubmit(e)}>
              <Stack spacing={3}>
                <TextField
                  disabled={this.state.isLoading}
                  fullWidth
                  autoComplete="Historial Snapshot Name"
                  type="text"
                  label="Historial Snapshot Name"
                  onChange={(e) => {
                    this.setState({ historialsnapshotname: e.target.value });
                  }}
                  required
                />

                <TextField
                  disabled={this.state.isLoading}
                  fullWidth
                  type="date"
                  autoComplete="TimeStamp"
                  inputProps={{ min: 1 }}
                  //   label="TimeStamp"
                  value={this.state.timestamp}
                  onChange={(e) => {
                    this.setState({ timestamp: e.target.value });
                  }}
                  required
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Days Pools
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value=''
                    label="Select Days Pools"
                    onChange={(e) => {
                      this.setState({
                        daysContacts: e.target.value,
                      });
                    }}
                    value={this.state.daysContacts}
                    required
                  >
                    <MenuItem value={""} disabled>
                      Select Days Pools
                    </MenuItem>
                    {[
                      {
                        name: "7 days",
                        contractAddress:
                          "0xb667c499b88ac66899e54e27ad830d423d9fba69",
                      },
                      {
                        name: "14 days",
                        contractAddress:
                          "0x027fc3a49383d0e7bd6b81ef6c7512afd7d22a9e",
                      },
                      {
                        name: "30 days",
                        contractAddress:
                          "0x8900475bf7ed42efcacf9ae8cfc24aa96098f776",
                      },
                      {
                        name: "60 days",
                        contractAddress:
                          "0x66b8c1f8de0574e68366e8c4e47d0c8883a6ad0b",
                      },
                      {
                        name: "90 days",
                        contractAddress:
                          "0x5745b7e077a76be7ba37208ff71d843347441576",
                      },
                      {
                        name: "180 days",
                        contractAddress:
                          "0xf420f0951f0f50f50c741f6269a4816985670054",
                      },
                    ].map((days, key) => (
                      <MenuItem key={key} value={`${days.contractAddress}`}>
                        {days.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  disabled={this.state.isLoading}
                  fullWidth
                  error={this.state.fileError}
                  autoComplete="file"
                  type="file"
                  id="HistorialSnapshotFile"
                  accept=".csv"
                  onChange={(e) => {
                    this.setState({ addcsvFile: e.target.files[0] });
                    this.readFile(e);
                  }}
                  placeholder={
                    "Upload Historial Snapshot File in format of csv"
                  }
                  required
                />
              </Stack>

              {!this.state.isLoading && (
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={this.state.isLoading}
                  style={{ marginTop: "25px" }}
                >
                  Generate
                </LoadingButton>
              )}

              {this.state.csvData.length && (
                <>
                  <CSVLink
                    filename={`${this.state.historialsnapshotname}.csv`}
                    data={this.state.csvData}
                    headers={this.state.headers}
                    target="_blank"
                    style={{ marginTop: "25px" }}
                    className="css-1iewgp6-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root"
                  >
                    <Icon
                      icon={CloudDownloadOutline}
                      style={{ fontSize: "20px" }}
                    />{" "}
                    &nbsp;Download CSV
                  </CSVLink>
                  {this.state.invalidAddress.length ? (
                    <CSVLink
                      filename={`${this.state.historialsnapshotname}InvalidAddresses.csv`}
                      data={this.state.invalidAddress}
                      headers={this.state.headers}
                      target="_blank"
                      style={{ marginTop: "25px" }}
                      className="css-1iewgp6-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root"
                    >
                      <Icon
                        icon={CloudDownloadOutline}
                        style={{ fontSize: "20px" }}
                      />{" "}
                      &nbsp;Download Invalid Addresses File
                    </CSVLink>
                  ) : null}
                </>
              )}
            </form>
          </Card>
        </Container>
      </Page>
    );
  }
}
export default HistorialSnapshot;
