import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import fileTextOutline from "@iconify/icons-eva/file-text-outline";
import FormControl from "@mui/material/FormControl";
import { toaster } from "../utils/toast";

// import { Card, Stack, TextField, Container, Typography } from '@mui/material';

// material
import {
  Card,
  Button,
  Container,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
// components
import Page from "../components/Page";
import { services } from "../services/index";

// ----------------------------------------------------------------------

export default function GenrateSnapshot() {
  const [isLoading, setLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [igoName, setIgoName] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);

  useEffect(() => {
    async function getCountryList() {
      const url = `/user/getUniqueCountries`;
      const sendRequest = await services.Get(url);

      if (sendRequest.status === 200) {
        setCountry(sendRequest.data.data);
      } else {
        setLoading(false);
      }
    }
    getCountryList();
  }, []);

  const genrateSnapshot = async () => {
    setLoading(true);
    let url = `/user/getUserStake`;

    if (selectedCountry) {
      url = `/user/getUserStake?country=${selectedCountry}`;
    }

    if (igoName) {
      url = `/user/getUserStake?name=${igoName}`;
    }

    if (igoName && selectedCountry) {
      url = `/user/getUserStake?country=${selectedCountry}&name=${igoName}`;
    }
    // this.setState({ showLoader: true });
    const sendRequest = await services.Get(url);

    if (sendRequest.status === 200) {
      setLoading(false);
      return toaster(`Your request have been received`, "success");
    } else {
      setLoading(false);
    }
  };

  return (
    <Page title="Generate Snapshot | Luval Snapshot">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Generate Snapshot
          </Typography>
        </Stack>

        <Card style={{ padding: "20px" }}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="Igo Name"
              value={igoName}
              onChange={(e) => {
                setIgoName(e.target.value);
              }}
              label="Name of Igo"
              required
            />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Country to Exclude
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Country"
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
                value={selectedCountry}
                required
              >
                <MenuItem value={""}></MenuItem>
                {country.map((country) => (
                  <MenuItem value={`${country}`}>{country}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Button
            variant="contained"
            // component={RouterLink}
            // to='/'
            onClick={() => {
              genrateSnapshot();
            }}
            disabled={isLoading}
            size="large"
            style={{ marginTop: "40px" }}
            startIcon={<Icon icon={fileTextOutline} />}
          >
            Generate Tier List
          </Button>
        </Card>
      </Container>
    </Page>
  );
}
