// material
import { Box, Grid, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// components
import Page from "../components/Page";
import { AppVerifiedUsers } from "../components/_dashboard/app";
import { services } from "../services";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [count, setCount] = useState([]);

  useEffect(() => {
    async function getUsersCount() {
      const url =
        localStorage.getItem("role") == "superadmin"
          ? `/apply-proj/list?approved=true`
          : `/user/group`;

      const getCount = await services.Get(url);

      if (getCount.status === 200) {
        console.log("getCount", getCount);
        setCount(getCount.data.data);
      }
    }
    getUsersCount();
  }, []);
  return (
    <Page title="Dashboard | Luval Snapshot">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Welcome to Luval Snapshot</Typography>
        </Box>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppTotalUsers />
          </Grid> */}
          {localStorage.getItem("role") == "superadmin" ? (
            count.length ? (
              <Grid item xs={12} sm={6} md={3}>
                <AppVerifiedUsers
                  name={`Approved Projects`}
                  count={count.length}
                />
              </Grid>
            ) : null
          ) : count.length ? (
            count.map((usersCount) => (
              <Grid item xs={12} sm={6} md={3}>
                <AppVerifiedUsers
                  name={usersCount._id}
                  count={usersCount.count}
                />
              </Grid>
            ))
          ) : null}
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppPendingUsers />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppRejectedUsers />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppResubmitUsers />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
