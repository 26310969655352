import { services } from '../services';
let networkId = 1;
async function fetchNetworkId() {
  networkId = await services.getNetworkId();
}
fetchNetworkId();

function getContractAddresses() {
  if (networkId === '0x4' || +networkId === 4)
    return {
      contractAddress: '0x259415431f1487d491bB980d9c49B4c3Af4A07DE',
    };
  else if (+networkId === 1 || networkId === '0x1')
    return {
      contractAddress: '0x259415431f1487d491bB980d9c49B4c3Af4A07DE',
    };
  else
    return {
      contractAddress: '0x259415431f1487d491bB980d9c49B4c3Af4A07DE',
    };
}
export default getContractAddresses;
