import { reject } from "lodash";
import * as XLSX from "xlsx";

export default async function readFile(e) {
  return new Promise((resolve, reject) => {
    try {
      const file = e.target.files[0];
      console.log(e, file);
      const reader = new FileReader();
      let processedData;
      reader.onload = async (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        processedData = await processData(data);
        resolve(processedData);
      };
      reader.readAsBinaryString(file);
    } catch (err) {
      reject(undefined);
    }
  });
}

const processData = (dataString) => {
  return new Promise((resolve, reject) => {
    try {
      const dataStringLines = dataString.split(/\r\n|\n/);
      const headers = dataStringLines[0].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );

      const list = [];
      for (let i = 1; i < dataStringLines.length; i++) {
        const row = dataStringLines[i].split(
          /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
        );
        if (headers && row.length == headers.length) {
          const obj = {};
          for (let j = 0; j < headers.length; j++) {
            let d = row[j];
            if (d.length > 0) {
              if (d[0] == '"') d = d.substring(1, d.length - 1);
              if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
            }
            if (headers[j]) {
              obj[headers[j]] = d;
            }
          }

          // remove the blank rows
          if (Object.values(obj).filter((x) => x).length > 0) {
            list.push(obj);
          }
        }
      }
      console.log(list);
      resolve(list);
    } catch (err) {
      reject(undefined);
    }
  });
};
