import * as React from "react";
import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CONTRACTADDRESS from "../config/contractData";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers";
import link2Outline from "@iconify/icons-eva/link-2-outline";
import { Icon } from "@iconify/react";
import linearContractAbi from "../abi/lenearVesting.json";
import accumulationABI from "../abi/accumulationABI.json";
import styled from "styled-components";
import PlusCircleFill from "@iconify/icons-eva/plus-circle-fill";
import CloseCircleFill from "@iconify/icons-eva/close-circle-fill";
import EditOutline from "@iconify/icons-eva/edit-outline";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import {
  getContractInstanceAbi,
  convertToWei,
  getTokenInstance,
} from "../helper/contractHelper";
import CONSTANT from "../constant";
// material
import { web3Services } from "../services/web3.service";
import { toaster } from "../utils/toast";
import {
  Card,
  Stack,
  TextField,
  Container,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import moment from "moment";
import { services } from "../services/index";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  compactAddress,
  ordinal_suffix_of,
  TimeStampToDateString,
  utcToLocalTimeStamp,
} from "src/contractData/function";
// import { update } from 'lodash-es';
import { web3 } from "src/web3";
import {
  linearByteCode,
  accumulationByteCode,
} from "src/contractData/byteCode";
import { createAllUserProofs, createRootHash } from "src/helper/Merkle";
import readFile from "src/helper/helperFunction";

class Claim extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contractAddressOfClaim: "",
      tokenAddress: "",
      idoName: "",
      totalAmount: 0,
      network: "",
      ownerId: "",
      contractInstance: null,
      claimData: [],
      hexProofs: [],
      contractAddress: "",
      showLoader: false,
      startDate: dayjs(),
      utcStartDate: dayjs(),
      endDate: dayjs(),
      utcEndDate: dayjs(),
      decimal: 0,
      phaseNo: 0,
      logo: "",
      linearStartAmount: "",
      csvFile: null,
      editId: "",
      isEdit: false,
      slotSize: 0,
      vestingsArr: [],
      currVestingPercentage: "",
      currVestingTimestamp: dayjs(),
      currVestingPhaseNo: "",
      currVestingRootHash: "",
      vestingType: "monthly",
      vestTotalArr: [],
      addVestingModal: false,
      currVestingPoolId: undefined,
      selectedVestingId: [],
      isSFNT: false,
      contractHash: "",
      idoDescription: "",
      projectId: "",
      dumpId: "",
      txnLoader: false,
      inputProps: {
        placeholder: "Select Start date in UTC ",
        required: true,
      },
      networkIds:
        CONSTANT.ENV === "PRODUCTION"
          ? CONTRACTADDRESS["MAINNETCHAINID"]
          : CONTRACTADDRESS["TESTNETCHAINID"],
      networkData:
        CONSTANT.ENV === "PRODUCTION"
          ? CONTRACTADDRESS["MAINETNETWORKID"]
          : CONTRACTADDRESS["TESTNETNETWORKID"],
      web3Data: {
        // isLoggedIn: true,
        // accounts: ["0x86286a1a5833cB1EFdd2ae5987dde5B79e847Eda"],
        isLoggedIn: false,
        accounts: [],
      },
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.contractAddressOfClaim !== prevState.contractAddressOfClaim
    ) {
      this.getContractABI();
    }
    if (this.state.currVestingPoolId !== prevState.currVestingPoolId) {
      if (this.state.currVestingPoolId !== undefined) {
        let p = this.state.vestingsArr[this.state.currVestingPoolId];
        this.setState({
          currVestingPhaseNo: p.phaseNo,
          currVestingTimestamp: p.timestamp,
          currVestingPercentage: p.vestingPercent,
        });
        if (p.status === "uploaded") {
          this.setState({ currVestingRootHash: p.rootHash });
        }
      }
    }
    if (this.state.tokenAddress !== prevState.tokenAddress) {
      this.getDecimals();
    }
  }

  componentDidMount = () => {
    console.log(dayjs(1668686536).unix());
    const path = window.location.pathname;
    let newPath = "";
    if (this.props.isCompleted) {
      newPath = path.replace("/dashboard/editCompletedClaimpools/", "");
    } else newPath = path.replace("/dashboard/editPendingClaimpools/", "");
    if (newPath && path.includes("edit")) this.callEditPoolDetails(newPath);

    this.connectMetamask();
    let ethereum = window.ethereum;
    ethereum.on("chainChanged", (chainId) => {
      if (chainId && this.state.networkIds.includes(chainId)) {
        this.setState({ network: chainId }, () => {
          this.getNetworkId(chainId);
        });
      } else {
        this.genrateError("Please select valid network");
      }
    });
  };
  // https://snapshotapi.seedify.fund/api/v1/claim/single/${pool._id}?csvData=true
  callEditPoolDetails = async (id) => {
    const url = `claim/${
      this.props.isCompleted ? "single" : "get-dump"
    }/${id}?csvData=true`;
    const sendRequest = await services.Get(url);
    if (sendRequest.status === 200) {
      this.setState({ isEdit: true, editId: id });
      const data = sendRequest.data.data;

      this.setState(
        {
          projectId: data._id,
          dumpId: this.props.isCompleted ? data.dumpId?._id : data._id,
          tokenAddress: data.tokenAddress,
          contractAddressOfClaim: data.contractAddress,
          logo: data.logo,
          idoName: data.name,
          idoDescription: data.description,
          phaseNo: data.phaseNo,
          totalAmount: data.amount,
          utcStartDate: TimeStampToDateString(data.timestamp),
          claimData:
            this.props.isCompleted && data.vestingType
              ? data.dumpId.uploadData
              : data.data,
          utcEndDate: TimeStampToDateString(+data.endTime),
          linearStartAmount: data.startAmount,
          vestingType: data.vestingType,
          vestingsArr: data.vestings,
          isSFNT: data.isSnft,
        },
        () => this.getContractABI()
      );
    } else {
      console.log("err");
    }
  };
  onTransactionError(error) {
    if (error.code === 4001) {
      this.genrateError("Transaction cancelled by user");
    } else if (error.code === -32602) {
      this.genrateError("Wrong parameters");
    } else if (error.code === -32603) {
      this.genrateError("Internal Error");
    } else if (error.code === -32002) {
      this.genrateError("Pending Previous request");
    } else {
      this.genrateError("Transaction has been reverted");
    }
  }

  genrateError = (text) => {
    return toaster(`${text}`, "error");
  };

  genrateSuccess = (text) => {
    return toaster(`${text}`, "success");
  };

  // connect metamask
  connectMetamask = async () => {
    try {
      const getAcountDetails = await web3Services.enableMetamask();
      const chainId = await web3.eth.net.getId();
      console.log("chainId", chainId);
      // const chainId = await window.ethereum.request({ method: "eth_chainId" });
      this.setState({ web3Data: getAcountDetails, network: chainId });
      this.getNetworkId(chainId);
    } catch (err) {
      console.log("error is:", err);
    }
  };

  // change network
  async changeNetwork(val) {
    let data = [];
    let ethereum = window.ethereum;
    if (CONSTANT.ENV === "PRODUCTION") {
      data = [
        {
          chainId: "0x38",
          chainName: "BSC MAINNET",
          nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
          blockExplorerUrls: ["https://testnet.bscscan.com"],
        },
      ];
      if (this.state.network === "0x1") {
        return this.genrateError("Please Connect to Etherium main network");
      } else if (this.state.network === "0x38") {
        data = [
          {
            chainId: "0x38",
            chainName: "BSC Mainnet",
            nativeCurrency: {
              name: "BNB",
              symbol: "BNB",
              decimals: 18,
            },
            rpcUrls: ["https://bsc-dataseed.binance.org/"],
            blockExplorerUrls: ["https://dataseed1.binance.org/"],
          },
        ];
      } else if (this.state.network === "0x89") {
        data = [
          {
            chainId: "0x89",
            chainName: "Polygon",
            nativeCurrency: {
              name: "Polygon",
              symbol: "MATIC",
              decimals: 18,
            },
            rpcUrls: ["https://bsc-dataseed.org/"],
            blockExplorerUrls: [
              "https://polygon-explorer-mainnet.chainstacklabs.com",
            ],
          },
        ];
      } else if (this.state.network === "0xa86a") {
        data = [
          {
            chainId: "0xa86a",
            chainName: "Avalance mainnet",
            nativeCurrency: {
              name: "Avalanche",
              symbol: "AVAX",
              decimals: 18,
            },
            rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
            blockExplorerUrls: ["https://cchain.explorer.avax.network/"],
          },
        ];
      }
    }
    // testnet
    else {
      if (this.state.network === "0x4") {
        return this.genrateError("Please Connect to Rinkeby Testnetwork");
      } else if (this.state.network === "0x61") {
        // bnb testnet
        data = [
          {
            chainId: "0x61",
            chainName: "Smart Chain - Testnet",
            nativeCurrency: {
              name: "BNB",
              symbol: "BNB",
              decimals: 18,
            },
            rpcUrls: ["https://bsc-testnet.public.blastapi.io/"],
            blockExplorerUrls: ["https://testnet.bscscan.com/"],
          },
        ];
      } else if (this.state.network === "0x13881") {
        data = [
          {
            chainId: "0x13881",
            chainName: "Polygon Testnet",
            nativeCurrency: {
              name: "Polygon",
              symbol: "MATIC",
              decimals: 18,
            },
            rpcUrls: ["https://rpc-mumbai.matic.today"],
            blockExplorerUrls: ["https://mumbai-explorer.matic.today/"],
          },
        ];
      } else if (this.state.network === "0xa869") {
        data = [
          {
            chainId: "0xa869",
            chainName: "Avax Testnet",
            nativeCurrency: {
              name: "Avlanche",
              symbol: "AVAX",
              decimals: 18,
            },
            rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
            blockExplorerUrls: ["https://cchain.explorer.avax-test.network/"],
          },
        ];
      } else {
        return this.genrateError("Invalid network");
      }

      try {
        await ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: data,
          })
          .then((resp) => {
            // this.getContractABI();
          });
        // .catch(() => console.log("error"));
      } catch {
        console.log("catched");
      }
    }

    /* eslint-disable */
    try {
      await ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: data,
        })
        .then((resp) => {
          // this.getRecordIds();
          // console.log('called', resp);
        });
      // .catch(() => console.log("error"));
    } catch {
      console.log("catched");
    }
  }
  async getNetworkId(val) {
    try {
      const id = await web3.eth.net.getId();
      // const id = await window.ethereum.request({ method: "eth_chainId" });
      this.setState({ networkId: id });
      if (val) {
        if (id !== val) {
          this.changeNetwork();
        } else {
          this.getContractABI();
        }
      } else {
        this.genrateError("Please select valid network");
      }
    } catch (error) {
      return 1;
    }
  }

  getDecimals = async () => {
    const tokenInstance = await getTokenInstance(this.state.tokenAddress);
    console.log(this.state.tokenAddress);
    console.log("tokenInstance", tokenInstance);
    const decimal = await tokenInstance.methods.decimals().call();
    // const decimal = await tokenInstance.methods.decimals().call();
    console.log("decimal", decimal);
    this.setState({ decimal });
  };

  precisedAmount = (amount) => {
    if (+this.state.decimal === 18) {
      return convertToWei(amount.toString());
    } else {
      let pow = (amount * Math.pow(10, +this.state.decimal)).toFixed(0);
      return pow.toString();
    }
  };
  getContractABI = async () => {
    try {
      if (this.state.contractAddressOfClaim && this.state.network) {
        const getInstance = await getContractInstanceAbi(
          this.state.contractAddressOfClaim,
          this.state.vestingType
        );
        this.setState({ contractInstance: getInstance });
        return true;
      }
    } catch (err) {
      return false;
    }
  };

  checkAllowance = async (address, contractAddress) => {
    return new Promise(async (resolve, reject) => {
      try {
        const getInstance = await getTokenInstance(this.state.tokenAddress);

        const checkAllowanceGiven = await getInstance.methods
          .allowance(address, this.state.contractAddressOfClaim)
          .call();
        if (!+checkAllowanceGiven) {
          await getInstance.methods
            .approve(
              this.state.contractAddressOfClaim,
              "100000000000000000000000000000"
            )
            .send({ from: this.state.web3Data.accounts[0] });
          resolve(true);
        } else {
          resolve(true);
        }
      } catch (err) {
        reject(false);
        this.setState({ showLoader: false });
        this.genrateError(err.message ? err.message : "Something went wrong");
      }
    });
  };
  addRecords = async (data) => {
    try {
      const url = `/claim/add-dump`;
      const headers = {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      };

      const sendRequest = await services.Post(url, data);

      if (sendRequest.status === 200) {
        this.setState({ showLoader: false });
        if (!sendRequest.data.data) {
          this.genrateError(
            `${
              sendRequest.data?.message
                ? sendRequest.data.message
                : "Something went wrong "
            }`
          );
          return false;
        }
        console.log("2", sendRequest.data);
        this.genrateSuccess(
          "Users data is saved . Please proceed with transactions"
        );
        return sendRequest.data;
      }
    } catch (err) {
      this.setState({ showLoader: false });
      this.genrateError(
        `${err.message ? err.message : "Something went wrong "}`
      );
    }
  };
  doSummation = (num1, num2) => {
    return parseFloat(+num1 + +num2);
  };
  separateData = (data) => {
    let addressArr = [];
    let valueArr = [];
    let startAmountArr = [];
    let totalSum = 0.0;
    let limit =
      this.state.slotSize > data.length ? data.length : this.state.slotSize;
    for (let i = 0; i < limit; i++) {
      if (this.state.vestingType === "linear") {
        startAmountArr.push(this.precisedAmount(this.state.linearStartAmount));
      }
      const eToken = (+data[i].eTokens).toFixed(6);

      totalSum = this.doSummation(
        totalSum,
        this.state.vestingType === "merkle"
          ? (
              (eToken *
                Number(
                  this.state.vestingsArr[this.state.currVestingPoolId]
                    .vestingPercent
                )) /
              100
            ).toFixed(6)
          : Number(eToken)
      );
      addressArr.push(data[i].walletAddress);
      console.log("presized", data[i].walletAddress, eToken);
      valueArr.push(
        this.precisedAmount(
          this.state.vestingType === "merkle"
            ? (
                (eToken *
                  Number(
                    this.state.vestingsArr[this.state.currVestingPoolId]
                      .vestingPercent
                  )) /
                100
              ).toFixed(6)
            : eToken
        )
      );
    }
    totalSum = totalSum.toFixed(6);
    return { addressArr, valueArr, totalSum, startAmountArr };
  };

  addDataToContract = async (
    address,
    value,
    amount,
    timestamp,
    phaseNo,
    totalAmount,
    startAmountArr
  ) => {
    let params =
      this.state.vestingType === "linear"
        ? [
            address,
            value,
            startAmountArr,
            timestamp,
            dayjs(this.state.utcEndDate).unix(),
            totalAmount,
          ]
        : [
            this.state.tokenAddress.trim(),
            totalAmount,
            phaseNo,
            timestamp,
            address,
            value,
          ];
    let fxn =
      this.state.vestingType === "linear" ? "massUpdate" : "updateUserTokens";
    console.log("params", params);
    return new Promise(async (resolve, reject) => {
      try {
        let hash = "";
        const contractInstance = this.state.contractInstance;
        const addToContract = await contractInstance.methods[fxn](...params)
          .send({ from: this.state.web3Data.accounts[0] })
          .on("transactionHash", (_hash) => {
            hash = _hash;
            resolve(hash);
          })
          .on("receipt", (receipt) => {
            // updateMerkleHash(hash);
          });
      } catch (err) {
        console.log(err);
        reject(false);
      }
    });
  };
  updateCompletedPooldetails = async (newVestingArr) => {
    this.setState({ showLoader: true });
    try {
      let data = {
        claimId: this.state.editId,
        logo: this.state.logo,
        name: this.state.idoName,
        description: this.state.idoDescription,
      };
      if (newVestingArr) data.vestings = newVestingArr;
      const url = `/claim/edit`;
      const sendRequest = await services.Post(url, data);

      if (sendRequest.status === 200) {
        this.setState({ showLoader: false });
        this.genrateSuccess("Pool data updated.");
        return sendRequest.data;
      }
    } catch (err) {
      this.setState({ showLoader: false });
      this.genrateError(
        `${err.message ? err.message : "Something went wrong "}`
      );
    }
  };
  newSubmit = async (e) => {
    e.preventDefault();
    if (this.props.isCompleted) return this.updateCompletedPooldetails();
    if (this.state.isEdit) {
      const checkAllowance = await this.checkAllowance(
        this.state.web3Data.accounts[0],
        this.state.contractAddressOfClaim
      );
      if (checkAllowance)
        return this.recursiveCalls(
          this.state.claimData,
          dayjs(this.state.utcStartDate).unix(),
          this.state.editId
        );
    }
    let contractDeployed;
    if (this.props.vestingType === "merkle") {
      contractDeployed = await this.deployLinearContract();
      if (!contractDeployed) return;
    }
    if (this.props.vestingType === "linear") {
      contractDeployed = await this.deployLinearContract();
      if (!contractDeployed) return;
    }
    const networkData = this.state.networkData.find(
      (o) => o.suffix === this.state.network
    );
    const datum = this.state.utcStartDate;
    var data = new FormData();

    data.append("csv", this.state.csvFile);
    data.append("contractAddress", this.state.contractAddressOfClaim);
    data.append("tokenAddress", this.state.tokenAddress);
    data.append("networkName", networkData.fullName);
    data.append("networkSymbol", networkData.symbol);
    data.append("networkId", networkData.suffix);
    data.append("amount", this.state.totalAmount);
    data.append("name", this.state.idoName);
    data.append("description", this.state.idoDescription);
    data.append("phaseNo", this.state.phaseNo);
    data.append("logo", this.state.logo);
    data.append("timestamp", dayjs(this.state.utcStartDate).unix());
    data.append("vestingType", this.state.vestingType);
    data.append("endTime", dayjs(this.state.utcEndDate).unix());
    data.append("startAmount", this.state.linearStartAmount);
    data.append("vestings", JSON.stringify(this.state.vestingsArr));
    data.append("isSnft", this.state.isSFNT);
    const addeddata = await this.addRecords(data);
    if (!addeddata) {
      return this.genrateError("Something went wrong");
    }

    ////////////////////////////////////////////////////////
    if (addeddata.status && this.state.vestingType === "merkle") {
      this.setState({ editId: addeddata.data._id, dumpId: addeddata.data._id });
      return this.updateMerkleHash(
        this.state.contractHash,
        addeddata.data._id,
        this.state.vestingsArr[0].timeStamp
      );
    }
    //////////////////////////////////////////////////////

    if (addeddata) {
      this.setState({ editId: addeddata.data._id });
      const checkAllowance = await this.checkAllowance(
        this.state.web3Data.accounts[0],
        this.state.contractAddressOfClaim
      );
      if (checkAllowance)
        this.recursiveCalls(
          addeddata.data.data,
          dayjs(this.state.utcStartDate).unix(),
          addeddata.data._id
        );
    }
  };

  recursiveCalls = async (addeddata, timeStamp, id) => {
    try {
      const usersSeparatedData = this.separateData(addeddata);
      console.log(usersSeparatedData, addeddata);
      const hash = await this.addDataToContract(
        usersSeparatedData.addressArr,
        usersSeparatedData.valueArr,
        convertToWei(this.state.totalAmount.toString()),
        timeStamp,
        this.state.vestingType === "merkle"
          ? this.state.currVestingPhaseNo
          : this.state.phaseNo,
        this.precisedAmount(usersSeparatedData.totalSum),
        usersSeparatedData.startAmountArr
      );
      if (hash) this.updateMerkleHash(hash, id, timeStamp);
    } catch (err) {
      console.log(err, err.message);
    }
  };

  updateMerkleHash = async (hash, dumpId, timeStamp) => {
    const data = JSON.stringify({
      transactionHash: hash,
      dumpId: dumpId,
      numberOfRecords: this.state.slotSize,
    });
    try {
      const url = `/claim/update-dump`;
      const headers = {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      };

      const sendRequest = await services.Post(url, data);

      if (sendRequest.status === 200) {
        if (this.state.vestingsArr.length) {
          return this.genrateSuccess("Please proceed with updating tokens");
        }
        if (sendRequest.data.data.data.length) {
          this.recursiveCalls(
            sendRequest.data.data.data,
            dayjs(this.state.utcStartDate).unix(),
            sendRequest.data.data._id
          );
        } else {
          this.genrateSuccess("All users are updated successfully ");
        }
      }
    } catch (err) {
      this.setState({ showLoader: false });
      this.genrateError(
        `${err.message ? err.message : "Something went wrong "}`
      );
    }
  };
  //[[root hashes ] , [starttimes timestamps],[total of vesting tokens] , tokenaddress    ]
  deployContract = async () => {
    if (!this.state.tokenAddress)
      return this.genrateError("Please enter the Token Address first");
    this.setState({ showLoader: true });
    const curr_abi =
      this.state.vestingType === "merkle" ? accumulationABI : linearContractAbi;
    const curr_byteCode =
      this.state.vestingType === "merkle"
        ? accumulationByteCode
        : linearByteCode;
    let deploy_contract = new web3.eth.Contract(curr_abi);
    let args;
    console.log("this check", this.state.vestingType);
    if (this.state.vestingType === "merkle") {
      const vestArr = this.state.vestingsArr;
      const rootHashes = vestArr.map((ele) => ele.rootHash);
      const timestamps = vestArr.map((ele) => ele.timestamp);
      const totalTokenArr = this.state.vestTotalArr;
      args = [rootHashes, timestamps, totalTokenArr, this.state.tokenAddress];
    } else args = [this.state.tokenAddress];
    console.log(args);
    await deploy_contract
      .deploy({
        data: curr_byteCode,
        arguments: args,
      })
      .send({
        from: this.state.web3Data.accounts[0],
      })
      .on("error", (error) => {
        this.setState({ showLoader: false });
        return false;
      })
      .on("transactionHash", (transactionHash) => {
        this.setState({
          contractHash: transactionHash,
        });
        this.genrateSuccess("Transaction Processing");
      })
      .on("receipt", (receipt) => {
        this.genrateSuccess(
          "Linear Contract deployed successfully . Submit the data and proceed with updating the users"
        );
        this.setState(
          {
            contractAddressOfClaim: receipt.contractAddress,
            showLoader: false,
            contractHash: receipt.transactionHash,
          },
          () => this.getContractABI()
        ); // contains the new contract address
        return true;
      });
  };
  updateVestings = (vestingKey, isDeleteKey) => {
    if (isDeleteKey) {
      let newVestArr = this.state.vestingsArr;
      newVestArr.splice(isDeleteKey - 1, 1);
      let newTotalTokensArr = this.state.vestTotalArr;
      newTotalTokensArr.splice(isDeleteKey - 1, 1);
      return this.setState({
        vestingsArr: [...newVestArr],
        vestTotalArr: newTotalTokensArr,
      });
    } else {
      let {
        currVestingPercentage,
        currVestingPhaseNo,
        currVestingRootHash,
        currVestingPoolId,
        idoName,
        currVestingTimestamp,
        vestingsArr,
        isEdit,
      } = this.state;
      let isErrMsg = undefined;
      if (!currVestingPercentage) isErrMsg = "Please enter vesting percentage";
      if (!idoName) isErrMsg = "Please enter IGO name";
      if (!currVestingTimestamp) isErrMsg = "Please enter vesting time";
      if (!currVestingRootHash) isErrMsg = "Please create the root hash";
      if (isErrMsg) return this.genrateError(isErrMsg);

      const obj = {
        name: `${idoName} (${ordinal_suffix_of(
          vestingsArr.length + 1
        )} Vesting)`,
        vestingPercent: currVestingPercentage,
        timestamp: currVestingTimestamp,
        rootHash: currVestingRootHash,
        phaseNo: currVestingPhaseNo,
      };

      if (currVestingPoolId !== undefined) {
        if (vestingsArr[currVestingPoolId].status === "uploaded") {
          obj.status = "uploaded";
          obj.txnHash = vestingsArr[currVestingPoolId].txnHash;
          obj._id = vestingsArr[currVestingPoolId]._id;
        }
        let editedVestingArr = vestingsArr;
        editedVestingArr[currVestingPoolId] = obj;
        this.setState({
          vestingsArr: [...editedVestingArr],
          currVestingPoolId: undefined,
        });
        if (isEdit) {
          return this.ChangeVestingStartTime(editedVestingArr);
        }
      } else {
        const newVestingArr = [...vestingsArr, obj];
        this.setState({
          vestingsArr: newVestingArr,
        });
        if (isEdit) {
          return this.addVestingToDeployedContract(newVestingArr);
        }
      }
      this.setState({ addVestingModal: false });
    }
  };
  createRootHashs = () => {
    const { claimData, currVestingPercentage, hexProofs } = this.state;
    let vestTotal = 0;
    const newUserArr = claimData.map((ele, key) => {
      console.log(ele.eTokens);
      vestTotal =
        vestTotal +
        Number((+(ele.eTokens * +currVestingPercentage) / 100).toFixed(6));
      let newVestTokens = web3.utils.toWei(
        (+(ele.eTokens * currVestingPercentage) / 100).toFixed(6)
      );
      return { walletAddress: ele.walletAddress, eTokens: newVestTokens };
    });
    const newRootHash = createRootHash(newUserArr);
    this.setState({
      currVestingRootHash: newRootHash,
      vestTotalArr: [
        ...this.state.vestTotalArr,
        web3.utils.toWei(vestTotal.toFixed(6).toString()),
      ],
    });
  };

  addMultiplePhaseRewards = async () => {
    this.setState({ showLoader: true });
    const { selectedVestingId, claimData, vestingsArr } = this.state;
    const checkAllowance = await this.checkAllowance(
      this.state.web3Data.accounts[0],
      this.state.contractAddressOfClaim
    );
    if (!checkAllowance) {
      this.setState({ showLoader: false });
      return this.genrateError("Please add allowance");
    }
    if (!selectedVestingId.length) {
      this.setState({ showLoader: false });
      return this.genrateError(
        "Please select the vesting you want to update the phase rewards"
      );
    }
    const selectedVestingTotalSums = selectedVestingId.map((ele) => {
      let vestTotal = 0;
      for (let i = 0; i < claimData.length; i++) {
        vestTotal += Number(
          (
            +(Number(claimData[i].eTokens) * +vestingsArr[ele].vestingPercent) /
            100
          ).toFixed(6)
        );
      }
      return web3.utils.toWei(vestTotal.toFixed(6).toString());
    });

    console.log(
      "final add merkle rewards=>",
      selectedVestingId,
      selectedVestingTotalSums
    );
    try {
      let hash = "";
      const contractInstance = this.state.contractInstance;
      const addToContract = await contractInstance.methods
        .addMultiplePhaseRewards(selectedVestingId, selectedVestingTotalSums)
        .send({ from: this.state.web3Data.accounts[0] })
        .on("transactionHash", (_hash) => {
          this.updateMerkleVestingHashes(_hash, selectedVestingId);
          hash = _hash;
        })
        .on("receipt", (receipt) => {
          this.genrateSuccess("Selected phase rewards added to blockchain. ");
          this.setState({ showLoader: false });
        });
    } catch (err) {
      this.setState({ showLoader: false });
      console.log(err);
    }
  };
  updateMerkleVestingHashes = async (hash, selectedVestingId) => {
    const data = JSON.stringify({
      dumpId: this.state.dumpId,
      vestingIndex: selectedVestingId,
      txnHash: hash,
    });
    try {
      const url = `/claim/topup-vesting`;

      const sendRequest = await services.Post(url, data);

      if (sendRequest.status === 200) {
        this.genrateSuccess("Vestings in progress");
      }
    } catch (err) {
      this.setState({ showLoader: false });
      this.genrateError(
        `${err.message ? err.message : "Something went wrong "}`
      );
    }
  };

  addVestingToUpload = (id) => {
    const { vestingsArr } = this.state;
    const prevIds = this.state.selectedVestingId;
    console.log(prevIds, id);
    if (vestingsArr[id].status === "uploaded")
      return this.genrateError(
        "Tokens are already uploaded for this vesting !"
      );
    if (prevIds.includes(id)) {
      this.setState({
        selectedVestingId: prevIds.filter((_, i) => i !== prevIds.indexOf(id)),
      });
    } else {
      this.setState({ selectedVestingId: [...prevIds, id] });
    }
  };
  // checkSelectedVestingToUpload=(id)=>{

  // }

  // -start- vesting date change function for merkle starts after pool deployement////////////////////////////////
  ChangeVestingStartTime = async (newVestArr) => {
    const { currVestingTimestamp, currVestingPoolId, currVestingPhaseNo } =
      this.state;
    if (!this.props.isCompleted) {
      return this.updateVestingInfoDB(newVestArr);
    }
    console.log(
      "this",
      currVestingTimestamp,
      currVestingPoolId,
      currVestingPhaseNo
    );
    try {
      this.setState({ txnLoader: true });
      let hash = "";
      const contractInstance = this.state.contractInstance;
      const result = await contractInstance.methods
        .changeStartTime(currVestingPoolId, currVestingTimestamp)
        .send({ from: this.state.web3Data.accounts[0] })
        .on("transactionHash", (_hash) => {
          hash = _hash;
        })
        .on("receipt", (receipt) => {
          this.updateVestingInfoDB(newVestArr);
          this.genrateSuccess("Start time changed for selected vesting. ");
        });
    } catch (err) {
      console.log(err);
    }
  };

  // <start> add vesting after pool deployement <>//////////
  addVestingToDeployedContract = async (newVestingArr) => {
    if (!this.props.isCompleted) {
      return this.updateCompletedPooldetails(newVestingArr);
    }
    let totalPhaseRewards = Number(
      (this.state.totalAmount * this.state.currVestingPercentage) / 100
    ).toFixed(6);
    console.log("this", this.state.vestingsArr);

    try {
      this.setState({ txnLoader: true });
      let hash = "";
      const contractInstance = this.state.contractInstance;
      await contractInstance.methods
        .addVesting(
          this.state.currVestingRootHash,
          this.state.currVestingTimestamp,
          web3.utils.toWei(totalPhaseRewards.toString())
        )
        .send({ from: this.state.web3Data.accounts[0] })
        .on("transactionHash", (_hash) => {
          hash = _hash;
        })
        .on("receipt", (receipt) => {
          this.updateVestingInfoDB(newVestingArr);
          this.genrateSuccess("Vesting Added to blockchian.Uploading to db.");
        });
    } catch (err) {
      this.genrateError("Transaction Reverted");

      console.log(err);
    }
  };
  //<end> add vesting after pool deployement //////////////

  updateVestingInfoDB = async (newVestingArr) => {
    try {
      const data = { dumpId: this.state.dumpId, vestings: newVestingArr };
      const url = `/claim/edit-vesting`;
      const sendRequest = await services.Post(url, data);

      if (sendRequest.status === 200) {
        this.setState({ addVestingModal: false, txnLoader: false });
        this.genrateSuccess("Vestings data updated");
      }
    } catch (err) {
      this.setState({ showLoader: false, txnLoader: false });
      this.genrateError(
        `${err.message ? err.message : "Something went wrong "}`
      );
    }
  };

  //  -end- vesting date change function for merkle ends

  getTotalAmount = (list) => {
    let totalSum = 0;
    for (let i = 0; i < list.length; i++) {
      totalSum = this.doSummation(
        totalSum,
        Number(+list[i].eTokens).toFixed(6)
      );
      console.log(totalSum, list[i].eTokens);
    }
    return totalSum;
  };
  render() {
    console.log(
      "+<<<",
      this.state.showLoader,
      this.state.contractAddressOfClaim
    );
    const { currVestingPoolId, vestingsArr, txnLoader, selectedVestingId } =
      this.state;
    let isEdit = this.state.isEdit;
    let completedPoolEdit = this.props.isCompleted;
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      borderRadius: "30px",
      boxShadow: 24,
      p: 4,
    };

    return (
      <Page title="Add Claim Pools | Luval Snapshot">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography
              variant="h4"
              gutterBottom
              onClick={() => this.updateMerkleVestingHashes()}
            >
              Claim Token
            </Typography>
            <Button
              variant="contained"
              startIcon={
                this.state.web3Data.isLoggedIn ? null : (
                  <Icon icon={link2Outline} />
                )
              }
            >
              {compactAddress(this.state.web3Data.accounts[0])}
            </Button>
          </Stack>

          <Card style={{ padding: "20px" }}>
            <form onSubmit={this.newSubmit}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Network
                  </InputLabel>
                  <Select
                    // disabled={isEdit}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Network"
                    value={this.state.network}
                    onChange={(e) => {
                      this.setState(
                        {
                          network: e.target.value,
                        },
                        () => {
                          this.getNetworkId(e.target.value);
                        }
                      );
                    }}
                    required
                  >
                    <MenuItem value={""}></MenuItem>
                    {this.state.networkData.map((networks) => (
                      <MenuItem value={`${networks.suffix}`}>
                        {networks.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Select Vesting Type</InputLabel>
                  <Select
                    // disabled={isEdit}
                    value={this.state.vestingType}
                    label="Select Vesting type"
                    onChange={(e) => {
                      this.setState({
                        vestingType: e.target.value,
                      });
                    }}
                    required
                  >
                    <MenuItem value="monthly">Monthly Vesting</MenuItem>
                    <MenuItem value="linear">Linear Vesting</MenuItem>
                    <MenuItem value="merkle">
                      Monthly Accumulation Vesting(merkle)
                    </MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="token Address"
                  type="text"
                  value={this.state.tokenAddress}
                  label="Token Address"
                  onChange={(e) => {
                    this.setState({ tokenAddress: e.target.value });
                  }}
                  required
                />

                <TextField
                  disabled
                  fullWidth
                  autoComplete="Decimal Points"
                  type="number"
                  value={this.state.decimal}
                  label="Decimal points"
                  onChange={(e) => {
                    this.setState({ decimal: e.target.value });
                  }}
                  required
                />

                <TextField
                  disabled={isEdit && !completedPoolEdit}
                  fullWidth
                  autoComplete="ido"
                  value={this.state.idoName}
                  onChange={(e) => {
                    this.setState({ idoName: e.target.value });
                  }}
                  type="text"
                  label="Ido Name"
                  required
                />

                <TextField
                  disabled={isEdit && !completedPoolEdit}
                  fullWidth
                  autoComplete="ido"
                  value={this.state.idoDescription}
                  onChange={(e) => {
                    this.setState({ idoDescription: e.target.value });
                  }}
                  type="text"
                  label="Ido Description"
                  required
                />

                <TextField
                  disabled={isEdit}
                  fullWidth
                  autoComplete="file"
                  type="file"
                  id="claimFile"
                  accept=".csv"
                  onChange={async (e) => {
                    this.setState({ csvFile: e.target.files[0] });
                    const list = await readFile(e);
                    console.log(list);
                    if (this.state.vestingType === "merkle") {
                      this.setState({ slotSize: list.length });
                    } else {
                      this.setState({ slotSize: 250 });
                    }
                    this.setState({
                      claimData: list,
                      totalAmount: this.getTotalAmount(list),
                    });
                  }}
                  placeholder={"Upload Claim File in format of csv"}
                  // label='Upload Claim File in format of csv'
                  required
                />
                <TextField
                  disabled={isEdit}
                  fullWidth
                  type={"number"}
                  autoComplete="rewards"
                  label="Total Rewards"
                  value={this.state.totalAmount}
                  onChange={(e) => {
                    this.setState({ totalAmount: e.target.value });
                  }}
                  required
                />
                {this.state.vestingType === "linear" ? (
                  <TextField
                    disabled={isEdit}
                    fullWidth
                    type={"number"}
                    autoComplete="rewards"
                    label="Start Amount"
                    value={this.state.linearStartAmount}
                    onChange={(e) => {
                      this.setState({ linearStartAmount: e.target.value });
                    }}
                    required
                  />
                ) : null}
                {this.state.vestingType !== "merkle" && (
                  <TextField
                    type="number"
                    fullWidth
                    autoComplete="Slot size"
                    value={this.state.slotSize}
                    onChange={(e) => {
                      this.setState({ slotSize: e.target.value });
                    }}
                    label="Slot size"
                    required
                  />
                )}
                {this.state.vestingType === "merkle" && (
                  <VestingTitle>
                    Add Vesting
                    <Icon
                      icon={PlusCircleFill}
                      width={30}
                      height={30}
                      onClick={() =>
                        this.setState({
                          addVestingModal: true,
                          currVestingRootHash: "",
                        })
                      }
                    />
                  </VestingTitle>
                )}

                {this.state.vestingType === "merkle" ? (
                  <VestingTable>
                    <div className="table-responsive">
                      <table cellPadding={0} cellSpacing={0}>
                        <thead>
                          <th>Sr. No.</th>
                          <th>Vesting percentage</th>
                          <th>Total vesting tokens</th>
                          <th>Time</th>
                          <th>Actions</th>
                          <th>Status</th>
                        </thead>
                        <tbody>
                          {vestingsArr.map((vest, key) => (
                            <tr
                              className={
                                selectedVestingId.includes(key)
                                  ? "active"
                                  : null
                              }
                              onClick={() => {
                                this.setState({ currVestingPoolId: key });
                                this.addVestingToUpload(key);
                              }}
                            >
                              <td>{key + 1}</td>
                              <td>{vest.vestingPercent}</td>
                              <td>
                                {(this.state.totalAmount *
                                  vest.vestingPercent) /
                                  100}
                              </td>
                              <td> {TimeStampToDateString(vest.timestamp)}</td>
                              <td>
                                <IconList>
                                  <Icon
                                    className="green"
                                    icon={EditOutline}
                                    width={25}
                                    height={25}
                                    onClick={() =>
                                      this.setState({
                                        addVestingModal: true,
                                        currVestingPoolId: key,
                                      })
                                    }
                                  />
                                  <Icon
                                    className="red"
                                    icon={CloseCircleFill}
                                    width={25}
                                    height={25}
                                    onClick={() =>
                                      this.updateVestings(key + 1, true)
                                    }
                                  />
                                </IconList>
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={
                                    vest.status === "uploaded" ? true : false
                                  }
                                  onClick={() => {
                                    this.setState({ currVestingPoolId: key });
                                    this.addVestingToUpload(key);
                                  }}
                                  onChecked={() => {
                                    this.setState({ currVestingPoolId: key });
                                    this.addVestingToUpload(key);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </VestingTable>
                ) : null}

                <Modal
                  open={this.state.addVestingModal}
                  onClose={() => this.setState({ addVestingModal: false })}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    {txnLoader ? (
                      <h4>Transaction processing ... </h4>
                    ) : (
                      <>
                        <h3 style={{ marginBottom: "25px" }}>
                          {`${this.state.idoName} (${ordinal_suffix_of(
                            vestingsArr.length + 1
                          )} Vesting)`}
                        </h3>
                        <TextField
                          disabled={
                            vestingsArr?.[currVestingPoolId]?.status ===
                              "uploaded" ||
                            (isEdit &&
                              vestingsArr?.[currVestingPoolId]?.timestamp <
                                new Date().getTime() / 1000)
                          }
                          fullWidth
                          value={this.state.currVestingPercentage}
                          type="text"
                          label="Add Vesting Percentage"
                          style={{ marginBottom: "25px" }}
                          onChange={(e) =>
                            this.setState({
                              currVestingPercentage: e.target.value,
                            })
                          }
                        />

                        <LocalizationProvider
                          fullWidth
                          style={{ marginBottom: "25px" }}
                          dateAdapter={AdapterDayjs}
                        >
                          <DateTimePicker
                            fullWidth
                            disabled={
                              isEdit &&
                              vestingsArr?.[currVestingPoolId]?.timestamp <
                                new Date().getTime() / 1000
                            }
                            renderInput={(props) => (
                              <TextField
                                style={{
                                  marginBottom: "25px",
                                  display: "flex",
                                }}
                                {...props}
                              />
                            )}
                            label="Select Start Date (UTC)"
                            value={this.state.currVestingTimestamp}
                            // value={utcToLocalTimeStamp(
                            //   +this.state.currVestingTimestamp * 1000
                            // )}
                            onChange={(newValue) => {
                              console.log("newValue", newValue);
                              this.setState({
                                startDate: newValue,
                                currVestingTimestamp: newValue,
                              });
                            }}
                            required
                          />
                        </LocalizationProvider>
                        {/* <LocalizationProvider
                          fullWidth
                          style={{ marginBottom: "25px" }}
                          dateAdapter={AdapterDayjs}
                        >
                          <DateTimePicker
                            fullWidth
                            disabled={
                              isEdit &&
                              vestingsArr?.[currVestingPoolId]?.timestamp <
                                new Date().getTime() / 1000
                            }
                            renderInput={(props) => (
                              <TextField
                                style={{
                                  marginBottom: "25px",
                                  display: "flex",
                                }}
                                {...props}
                              />
                            )}
                            label="Select Start Date (UTC)"
                            value={utcToLocalTimeStamp(
                              +this.state.currVestingTimestamp * 1000
                            )}
                            onChange={(newValue) => {
                              const date = new Date(newValue);
                              var newTimeStamp = new Date(
                                date.getUTCFullYear(),
                                date.getUTCMonth(),
                                date.getUTCDate(),
                                date.getUTCHours(),
                                date.getUTCMinutes(),
                                date.getUTCSeconds()
                              );
                              // var date = newValue;
                              // var now_utc = Date.UTC(
                              //   date.getFullYear(),
                              //   date.getMonth(),
                              //   date.getDate(),
                              //   date.getHours(),
                              //   date.getMinutes(),
                              //   date.getSeconds()
                              // );
                              this.setState({
                                startDate: newTimeStamp / 1000,
                                currVestingTimestamp: newTimeStamp / 1000,
                              });
                            }}
                            required
                          />
                        </LocalizationProvider> */}
                        <ParentField style={{ marginBottom: "25px" }}>
                          <TextField
                            disabled={completedPoolEdit}
                            type="text"
                            fullWidth
                            autoComplete="Phase"
                            value={
                              vestingsArr?.[currVestingPoolId]?.status ===
                              "uploaded"
                                ? vestingsArr?.[currVestingPoolId].rootHash
                                : this.state.currVestingRootHash
                            }
                            onChange={(e) => {
                              this.setState({
                                currVestingRootHash: e.target.value,
                              });
                            }}
                            label="Root Hash"
                            required
                          />
                          <LoadingButton
                            disabled={
                              vestingsArr?.[currVestingPoolId]?.status ===
                              "uploaded"
                            }
                            type="button"
                            size="small"
                            variant="contained"
                            // loading={this.state.showLoader}
                            onClick={() => this.createRootHashs()}
                          >
                            Create
                          </LoadingButton>
                        </ParentField>

                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          onClick={() => {
                            this.updateVestings();
                          }}
                        >
                          Add
                          {/* ) : null} */}
                        </LoadingButton>
                      </>
                    )}
                  </Box>
                </Modal>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    disabled={isEdit}
                    renderInput={(props) => <TextField {...props} />}
                    label="Select Start Date (UTC)"
                    value={this.state.utcStartDate}
                    // value={utcToLocalTimeStamp(+this.state.startDate * 1000)}
                    onChange={(newValue) => {
                      // const date = new Date(newValue);
                      // var newTimeStamp = new Date(
                      //   date.getUTCFullYear(),
                      //   date.getUTCMonth(),
                      //   date.getUTCDate(),
                      //   date.getUTCHours(),
                      //   date.getUTCMinutes(),
                      //   date.getUTCSeconds()
                      // );
                      console.log("newValue", newValue);

                      this.setState({
                        utcStartDate: newValue,
                      });
                    }}
                    required
                  />
                </LocalizationProvider>
                {this.state.vestingType === "linear" ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      disabled={isEdit}
                      renderInput={(props) => <TextField {...props} />}
                      label="Select End Date"
                      value={this.state.utcEndDate}
                      onChange={(newValue) => {
                        this.setState({
                          utcEndDate: newValue,
                        });
                      }}
                      required
                    />
                  </LocalizationProvider>
                ) : null}
                <TextField
                  disabled={completedPoolEdit}
                  type="number"
                  fullWidth
                  autoComplete="Phase"
                  value={this.state.phaseNo}
                  onChange={(e) => {
                    this.setState({ phaseNo: e.target.value });
                  }}
                  label="Phase No"
                  required
                />

                <TextField
                  disabled={isEdit && !completedPoolEdit}
                  fullWidth
                  autoComplete="logo"
                  type="text"
                  label="Logo Url"
                  value={this.state.logo}
                  onChange={(e) => {
                    this.setState({ logo: e.target.value });
                  }}
                  required
                />
                <ParentField>
                  <TextField
                    // disabled={isEdit}
                    fullWidth
                    autoComplete="Contract Address"
                    type="text"
                    value={this.state.contractAddressOfClaim}
                    label="Contract Address"
                    disabled={this.state.network === "" || isEdit}
                    onChange={(e) => {
                      this.setState(
                        { contractAddressOfClaim: e.target.value },
                        () => {
                          this.getContractABI();
                        }
                      );
                    }}
                    required
                  />
                  {this.state.vestingType === "linear" ||
                  this.state.vestingType === "merkle" ? (
                    <LoadingButton
                      disabled={this.state.contractAddressOfClaim}
                      type="button"
                      size="small"
                      variant="contained"
                      loading={
                        !this.state.contractAddressOfClaim &&
                        this.state.showLoader
                      }
                      onClick={() => this.deployContract()}
                    >
                      Deploy{" "}
                      {this.state.vestingType === "merkle"
                        ? "Monthly vesting accumulation "
                        : "Linear "}{" "}
                      Contract
                    </LoadingButton>
                  ) : null}
                </ParentField>
              </Stack>
              <br />
              {this.state.vestingType !== "monthly" ? (
                <>
                  <h4>SFNT Drop</h4>
                  <CustomSwitch>
                    <label className="switch">
                      <input
                        type="checkbox"
                        value={this.state.isSFNT}
                        // defaultChecked={}
                        onChange={(e) =>
                          this.setState({ isSFNT: !this.state.isSFNT })
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </CustomSwitch>
                </>
              ) : null}
              <LoadingButton
                fullWidth
                disabled={!this.state.contractAddressOfClaim}
                size="large"
                type="submit"
                variant="contained"
                loading={
                  this.state.showLoader && this.state.contractAddressOfClaim
                }
                style={{ marginTop: "25px" }}
              >
                Submit
              </LoadingButton>
              {/* //////////////////////////////////////// */}
              {this.state.vestingType === "merkle" && (
                <LoadingButton
                  fullWidth
                  disabled={!this.state.contractAddressOfClaim}
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={() => this.addMultiplePhaseRewards()}
                  loading={
                    this.state.showLoader && this.state.contractAddressOfClaim
                  }
                  style={{ marginTop: "25px" }}
                >
                  Add Vesting Tokens
                </LoadingButton>
              )}
            </form>
          </Card>
        </Container>
      </Page>
    );
  }
}
export default Claim;

const ParentField = styled.div`
  position: relative;
  button {
    position: absolute;
    right: 10px;
    top: calc(50% - 20px);
    color: #fff;
    background-color: #5bb3fc;
    padding: 10px 22px;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    font-family: Public Sans, sans-serif;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
`;
const CustomSwitch = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: #2196f3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
`;
const VestingTitle = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
    cursor: pointer;
    color: #5bb3fc;
    box-shadow: 0px 0px 5px 0px #70bdfc;
    border-radius: 50%;
    :hover {
      box-shadow: 0px 0px 5px 1px #70bdfc;
      transition: 0.5s ease all;
    }
  }
`;

const ItemRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ItemList = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dbeefe;
  border-radius: 30px;
  margin: 0px 15px 15px 0px;
  width: calc(33.33% - 15px);
  background-color: #f6f9fb;
  &.active {
    border: 1px solid #5bb3fc;
  }
  &.completed {
    border: 1px solid #4caf50;
    background-color: #ddf9de;
  }
  :hover {
    border: 1px solid #b9d9f5;
  }
  p {
    border-right: 1px solid #f6f9fb;
    padding: 10px;
    width: 40px;
    text-align: center;
    background: linear-gradient(90deg, #5bb3fc, #d9edfe);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    &.completed {
      background: linear-gradient(90deg, #70c274, #ddf9de);
    }
  }
  :last-child {
    margin-right: 0px;
  }
  .right-part {
    position: relative;
    width: -webkit-fill-available;
    font-size: 14px;
    .icon-list {
      position: absolute;
      right: 5px;
      top: calc(50% - 15px);
      svg {
        margin: 0px 2px;
        cursor: pointer;
        color: #ff4842;
        box-shadow: 0px 0px 2px 0px #ff4842;
        border-radius: 50%;
        :hover {
          box-shadow: 0px 0px 5px 1px #70bdfc;
          transition: 0.5s ease all;
        }
        &.green {
          color: #4caf50;
          box-shadow: 0px 0px 2px 0px #4caf50;
        }
      }
    }
  }
`;

const DateText = styled.div`
  padding: 10px;
`;

const VestingPer = styled.div`
  background-color: #d9edfe;
  padding: 12px 10px;
  font-size: 14px;
  &.completed {
    background-color: #c3f1c5;
  }
`;

const VestingTable = styled.div`
  .table-responsive {
    overflow-x: auto;
  }
  margin-bottom: 20px !important;
  table {
    width: 100%;
    border-bottom: 1px solid #dce0e4;
    tr:hover {
      background-color: #cde8fd;
    }
    tr {
      cursor: pointer;
      &.active {
        td {
          border: 2px solid #5bb3fc;
        }
      }
    }
    td {
      border: 1px solid #dce0e4;
      text-align: center;
      border-right: 0px;
      border-bottom: 0px;
      padding: 5px 10px;
      white-space: nowrap;
      :last-child {
        border-right: 1px solid #dce0e4;
      }
      svg {
        cursor: pointer;
        margin: 5px;
        &.green {
          color: #2196f3;
        }
        &.red {
          color: #e10404;
        }
      }
    }
  }
`;

const IconList = styled.div`
  display: flex;
  align-items: Center;
  justify-content: center;
`;
