const a = {};

const TESTNET = {};

TESTNET.ETH = '0x1Cc5646c07283D75E3cf0072D70337F91A4300B3';
TESTNET.BNB = '0x4977EE513503e4bA948655649C054b8565B640d2';
TESTNET.POLYGON = '0x5355DCbadBD0f15D7aBf53Db3aEa6281Ef18B68D';
TESTNET.AVAX = '0xEA71813ef9AcB656BC98C9C3ba1fdd761536B0c9';

a.TESTNET = TESTNET;

const MAINNET = {};

MAINNET.ETH = '0x7ab80a6f9070686412cb788bd0d6a71b4fc605b5';
MAINNET.BNB = '0x38409034d3bfCfBE16D3502d59dbFb0aa193e4A5';
MAINNET.POLYGON = '0x0Ed48B5CF25bbd1ee1fC5Db2e7020788516B9A1C';
MAINNET.AVAX = '0xEA71813ef9AcB656BC98C9C3ba1fdd761536B0c9';

a.MAINNET = MAINNET;

const MAINETNETWORKID = [
  {
    id: 1,
    suffix: '0x1',
    name: 'ETH',
    fullName: 'ethereum',
    symbol: 'ETH',
    address: '0x1Cc5646c07283D75E3cf0072D70337F91A4300B3',
  },
  {
    id: 56,
    suffix: '0x38',
    name: 'BSC',
    fullName: 'binance',
    symbol: 'BNB',
    address: '0x4977EE513503e4bA948655649C054b8565B640d2',
  },
  {
    id: 137,
    suffix: '0x89',
    name: 'POLYGON',
    fullName: 'polygon',
    symbol: 'MATIC',
    address: '0x1Cc5646c07283D75E3cf0072D70337F91A4300B3',
  },
  {
    id: 43114,
    suffix: '0xa86a',
    name: 'AVAX',
    fullName: 'avalanche',
    symbol: 'AVAX',
    address: '0xEA71813ef9AcB656BC98C9C3ba1fdd761536B0c9',
  },
];

a.MAINETNETWORKID = MAINETNETWORKID;

const TESTNETNETWORKID = [
  {
    id: 4,
    suffix: '0x4',
    name: 'ETH',
    fullName: 'ethereum',
    symbol: 'ETH',
    address: '0xAd59AaA819FEED862Fa11672f7BdCD691dAe65ED',
  },
  {
    id: 97,
    suffix: '0x61',
    name: 'BSC',
    fullName: 'binance',
    symbol: 'BNB',
    address: '0x4977EE513503e4bA948655649C054b8565B640d2',
  },
  {
    id: 80001,
    suffix: '0x13881',
    name: 'POLYGON',
    fullName: 'polygon',
    symbol: 'MATIC',
    address: '0xC59fe24351AdAf9f86240c1B7D6a1856e97bd36b',
  },
  {
    id: 43114,
    suffix: '0xa869',
    name: 'AVAX',
    fullName: 'avalanche',
    symbol: 'AVAX',
    address: '0xEA71813ef9AcB656BC98C9C3ba1fdd761536B0c9',
  },
];

a.TESTNETCHAINID = ['0x4', '0x61', '0x13881', '0xa869'];
a.MAINNETCHAINID = ['0x1', '0x38', '0x89', '0xa86a'];

a.TESTNETNETWORKID = TESTNETNETWORKID;

module.exports = a;
